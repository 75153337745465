.navbarContainer {
  margin: 0 0rem;
  padding: 0 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-family: CircularStd-Black;
  color: var(--mainTextColor);
}

.navbarCustom {
  height: 60px;
  position: relative;
  border-bottom: solid var(--mainTextColor);
}

.logo h1 {
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
}
