.footerCustom {
  height: 30px;
  position: relative;
  border-top: solid var(--mainTextColor);
  font-family: 'CircularStd-Book';
}

.socialLinks {
  justify-content: center;
  display: flex;
  gap: 2rem;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.socialLink {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white !important;
  text-decoration: none !important;
  transition: color 0.2s ease-in-out;
}

.socialLink:hover {
  color: #ADD8E6 !important;
}

.socialLink svg {
  width: 18px;
  height: 18px;
}


@media only screen and (max-width: 1024px) {
.footerCustom {
    text-align: center;
}

.socialLinks {
  justify-content: center;
  display: inline-block;
    margin-bottom: 0rem;
}

.socialLink {
  display: flex;
  align-items: center;
}

}

