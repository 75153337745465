.mainContainer {
  padding: 0 5rem;
  max-width: 75%;
  margin: 0 auto;
}

.contentContainer {
}


.mainText {
  margin-top: 5rem;
  font-family: 'CircularStd-Medium';
  font-size: 1.8rem;
  margin-right: 1rem;
  max-width: 29rem;
}
.secondaryText {
  margin-top: 4rem;
  font-family: 'CircularStd-Book';
  font-size: 1.1rem;
  font-weight: normal;
  text-align: justify;
}

.square {
  border-radius: 0 !important;
}
.offset {
  box-shadow: 0.4em 0.3em 0 0 var(--mainTextColor);
}
.offset:hover {
  box-shadow: 0 0 0 0 var(--mainTextColor);
  color: var(--mainTextColor);
  text-decoration: none;
}

.resume-btn {
  display: inline-block;
  font-weight: 400;
  color: var(--mainTextColor);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.resume-btn-layered {
  background: none;
  border: solid 2px var(--mainTextColor);
  font-family: 'CircularStd-Black', sans-serif !important;
  font-size: 10pt;
  font-weight: 500;
  line-height: 1;
  padding: 10px 26px;
  transition: 0.1s ease-out;
  -webkit-transition: 0.1s ease-out;
  color: var(--mainTextColor);
}

.loxContainer {
  margin-top: 3rem;
}

.headings {
  font-family: 'CircularStd-Medium';
  font-size: 1.5rem;
  margin-right: 1rem;
  max-width: 29rem;
}

.sections {
  margin-top: 3rem;
  margin-bottom: 5rem;
}

/* Reset all link styles first */
.blogLink,
.blogLink:link,
.blogLink:visited,
.blogLink:hover,
.blogLink:active {
  color: #87CEEB;
  text-decoration: none;  /* Removes default underline */
  position: relative;
  transition: color 0.2s ease-in-out;
}

@media only screen and (max-width: 1024px) {
  .mainContainer {
  padding: 0 1rem;
  max-width: 90%;
  margin: 0 auto;
}

}
