.card {
  width: 100%;
  height: 425px;
  background-color: #eee;
  cursor: pointer;
  display: block;
}

.imageOverlay {
  /* position: absolute; */
  /* top: 0;
  bottom: 0;
  left: 15px;
  right: 0; */
  height: 100%;
  width: 100%;
  /* width: -webkit-calc(100% - 30px); */
  opacity: 0;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  background-color: var(--mainTextColor);
  z-index: 100;
}

.imageOverlay:hover {
  opacity: 0.7;
}

.imageOverlayText {
  color: #fff;
  padding-top: 40px;
  padding-left: 40px;
  max-width: 60%;
  line-height: 1;
}
.projectTitle {
  font-family: CircularStd-Black, sans-serif;
  font-size: 20pt;
  font-weight: 900;
}

.modalContainer {
  border-radius: 0 !important;
}
.modalTitle {
  font-family: CircularStd-Medium, sans-serif;
}
.modalDescription {
  font-family: CircularStd-Book, sans-serif;
}
