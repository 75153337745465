.documentationContainer {
  margin: 5rem 5rem 0;
  color: var(--mainTextColor);
}

.docsHeading {
  margin-bottom: 3rem;
}

.docsSubHeading {
  font-family: 'CircularStd-Medium', sans-serif;
}

.docsDesc {
  font-family: 'CircularStd-Book', sans-serif;
}

@media (max-width: 1024px) {
  .documentationContainer {
    margin: 5rem 2rem 0;
  }
}
