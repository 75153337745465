.editorContainer {
  max-height: 500px;
  overflow: auto;
  margin: 2rem auto;
  width: 90%;
  border-radius: 1.5rem;
}

.editor {
  font-size: 1rem;
  background-color: var(--editorBackgroundColor);
  font-family: ui-monospace, SFMono-Regular, SF Mono, Consolas, Liberation Mono, Menlo, monospace;
}

.runButton {
  background-color: var(--mainTextColor);
  border: solid 2px var(--mainTextColor);
  border-radius: 0.5rem;
  color: var(--secondaryTextColor);
  padding: 0.3rem 2rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: CircularStd-Medium, sans-serif;
  margin: 0 1rem;
}

.runButton:hover {
  background-color: var(--editorBackgroundColor);
  color: white;
}
.outputArea {
  margin: 1rem auto;
  width: 90%;
  white-space: pre;
  border: solid 1px var(--mainTextColor);
  color: var(--mainTextColor);
}
