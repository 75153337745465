@font-face {
  font-family: "CircularStd-Black";
  src: url("./fonts/CircularStd/CircularStd-Black.woff") format("woff");
}

@font-face {
  font-family: "CircularStd-Book";
  src: url("./fonts/CircularStd/CircularStd-Book.woff") format("woff");
}

@font-face {
  font-family: "CircularStd-Medium";
  src: url("./fonts/CircularStd/CircularStd-Medium.woff") format("woff");
}

:root {
  --mainTextColor: rgba(254,254,254,0.9);
  --secondaryTextColor: #212529;
  --editorBackgroundColor: #181a19;
  --backgroundColor: rgba(30,39,50,1);
}

body {
  background-color: var(--backgroundColor) !important;
  color: var(--mainTextColor) !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

